import React from 'react';
//import PropTypes from 'prop-types';

import RealIndexPage from '../components/real-pages/index';

function IndexPage(props) {
  return (<RealIndexPage />);
}

IndexPage.propTypes = {

};

export default IndexPage;
