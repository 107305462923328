import React, { useState } from 'react';

//import PropTypes from 'prop-types';

import Image from '../../ui/image';
import LinkButton from '../../ui/link-button';

import { configureBEMClasses } from '../../../constants/functions';
import video from '../../../assets/videos/5mb.mp4';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function RealIndexPage(props) {
    const [isLoadedVideo, setIsLoadedVideo] = useState(false);

    const loadVideo = () => {
        const canPlayTimeout = setTimeout(() => {
            setIsLoadedVideo(true);

            clearTimeout(canPlayTimeout);
        }, 400);
    }

    const videoElement = (
        <video
            className={classes['video']}
            autoPlay
            muted
            playsInline
            loop
            onCanPlay={loadVideo}
            style={{ display: isLoadedVideo ? 'block' : 'none' }}
        >
            <source src={video} type="video/mp4" />
            <track kind="captions"></track>
            Your browser does not support the video tag.
        </video>);

    const loader = (<div className={classes['loader']}></div>);

    return (
        <section className={classes['section-video']}>
            <div className={classes['video-wrapper']}>
                {isLoadedVideo ? null : loader}
                {videoElement}
            </div>

            <div className={classes['section-video__content']} style={{ display: isLoadedVideo ? 'block' : 'none' }}>
                <div className={classes['section-video__logo-container']}>
                    <Image
                        imageType="svg"
                        imageName="logo"
                        imageExtention="svg"
                        alt="This is site logo."
                        imageClasses={classes['section-video__logo']}
                    />
                </div>
                <LinkButton url="/home" linkClasses={classes['section-video__button']}>Enter</LinkButton>
            </div>
        </section>
    );
}

RealIndexPage.propTypes = {

};

export default RealIndexPage;
